import Vue from "vue/dist/vue.esm";
import vSelect from "vue-select";
import axios from "axios";

import "vue-select/dist/vue-select.css";
import "../src/vue_select.scss";

Vue.component("v-select", vSelect);

let token = document.getElementsByName("csrf-token")[0].getAttribute("content");
axios.defaults.headers.common["X-CSRF-Token"] = token;
axios.defaults.headers.common["Accept"] = "application/json";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#userDeviceModels");

  if (el != null) {
    const user_id = el.dataset.user;
    const selectedDeviceModel = el.dataset.model

    const app = new Vue({
      el: el,
      components: { vSelect },
      data: {
        deviceModelId: null,
        selectedDeviceModel: selectedDeviceModel,
        deviceVersions: [],
        userId: user_id,
        userDeviceVersions: [],
        selected: []
      },
      computed: {},
      watch: {
        deviceModelId: function(val) {
          this.selected = []
          if (!!val) {
            this.getDeviceVersions();
          }

        }
      },
      mounted: function() {
        if (!!this.selectedDeviceModel) {
          this.deviceModelId = parseInt(this.selectedDeviceModel)
        }
      },
      methods: {
        getDeviceVersions: function() {
          let vm = this;
          axios
            .get("/api/backoffice/device_versions", {
              params: { user_id: vm.userId, device_model_id: vm.deviceModelId }
            })
            .then(function(resp) {
              vm.deviceVersions = resp.data.device_versions;
              vm.userDeviceVersions = resp.data.user_device_versions;
              resp.data.user_device_versions.forEach(dv => vm.selected.push(dv.id))
            })
            .catch(function(error) {
              console.log(error);
            });
        },
        deviceVersionChecked: function(dv_id) {
          let ind = this.userDeviceVersions.findIndex(dv => dv.id == dv_id);
          return ind >= 0;
        },
        updateUserVersions: function() {
          let vm = this;
          axios
            .post(`/api/backoffice/user_device_models/`, {
              user_device_model: { user_id: vm.userId, device_version_ids: vm.selected, device_model_id: vm.deviceModelId }
            })
            .then(function(resp) {
              window.location.replace(`/accounts/${vm.userId}`);
            })
            .catch(function(error) {
              console.log(error);
            });
        },
        toggleCheck: function(e) {
          let dv_id = e.target.dataset.id;
          if (this.deviceVersionChecked(dv_id)) {
            let id = this.userDeviceVersions.find(dv => dv.id == dv_id).id;
            let ind = this.selected.indexOf(id);
            if (ind < 0) {
              this.selected.push(id);
            } else {
              this.selected.splice(ind, 1);
            }
          } else {
            let ind = this.selected.indexOf(dv_id);
            if (ind < 0) {
              this.selected.push(dv_id);
            } else {
              this.selected.splice(ind, 1);
            }
          }
        }
      }
    });
  }
});
