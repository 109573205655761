import Vue from "vue/dist/vue.esm";
import BarChartAdvanced from "./components/BarChartAdvanced.vue";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#analytics_by_author");

  if (el != null) {
    const app = new Vue({
      el: el,
      components: {
        BarChartAdvanced,
      },
    });
  }
});
