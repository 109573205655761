import Vue from "vue/dist/vue.esm";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector('#deviceTestings');

  if (el != null) {
    const app = new Vue({
      el: el,
      data: {
        showTable: false,
        testResults: {}
      },
      methods: {
        changeElement: function(el) {
          let newResults = JSON.parse(el.target.getAttribute('data-object')) || {}
          if (newResults.id === this.testResults.id) {
            this.showTable = !this.showTable
          }
          else
          {
            this.showTable = true
          }
          this.testResults = newResults
        },
        textColor: function(value) {
          if (value === 'Pass') {
            return "#28a745"
          }
          if (value === 'Fail') {
            return "#dc3545"
          }
          return "#000"
        }
      },
      computed: {}
    });
  }
});
