<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :style="{ maxHeight: maxHeight }"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChartAdvanced",
  components: { Bar },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: String,
      default: "30vh",
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        borderWidth: 2,
        responsive: true,
        scales: {
          y: {
            title: {
              display: false,
            },
            ticks: {
              precision: 0,
            },
          },
        },
      },
    };
  },
};
</script>
