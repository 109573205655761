import Vue from "vue/dist/vue.esm";
import BarChart from "./components/BarChart.vue";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#analytics");

  if (el != null) {
    const app = new Vue({
      el: el,
      components: {
        BarChart,
      },
    });
  }
});
